<template>
  <el-container>
    <el-main>
      <h2 class="depTitle" v-if="userType=='1'">科室薪酬数据填报</h2>
      <el-table :data="SalaryListtableData" style="width: 95%; margin: 0 auto" border center>
        <el-table-column type="index" label="序号" width="55" align="center">
        </el-table-column>
        <el-table-column prop="year" label="基期年份" align="center">
        </el-table-column>
        <el-table-column prop="updateDate" label="更新时间" align="center">
        </el-table-column>
        <el-table-column label="填报状态" align="center">
          <template slot-scope="scope">
            <div class="activeStatus" v-if="scope.row.status == 0">√</div>
            <div class="noStatus" v-else>√</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template style="display: flex" slot-scope="scope">
            <el-button size="mini" type="primary" @click="SalaryDetails(scope.row)"><i class="el-icon-document-copy"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background @current-change="handleSalaryCurrentChange" :current-page="SalarypageNum" :page-size="SalarypageSize" layout="prev, pager, next" :total="Salarytotal">
      </el-pagination>
    </el-main>
    <!-- 点击操作科室工作量填报框 -->
    <el-dialog :title="yearTitle" :visible.sync="SalaryDetaildialogVisible" width="100%" :before-close="SalaryDetaildialogVisibleClose" top="1vh" class="tanchu" append-to-body>
      <el-table :data="SalaryDetailtableData" style="width: 95%; margin: 0 auto">
        <el-table-column prop="month" label="月份" width="55" align="center">
        </el-table-column>
        <el-table-column prop="fixed" label="基本工资" align="center">
        </el-table-column>
        <el-table-column prop="work" label="绩效薪酬" align="center">
        </el-table-column>
        <el-table-column label="津贴补贴" align="center">
          <el-table-column prop="allowOne" label="岗位津贴" align="center">
          </el-table-column>
          <el-table-column prop="allowTwo" label="职务津贴" align="center">
          </el-table-column>
          <el-table-column prop="allowThree" label="技能津贴" align="center">
          </el-table-column>
          <el-table-column prop="allowFour" label="住房补贴" align="center">
          </el-table-column>
          <el-table-column prop="allowFive" label="通信补贴" align="center">
          </el-table-column>
          <el-table-column prop="allowSix" label="交通补贴" align="center">
          </el-table-column>
          <el-table-column prop="allowSeven" label="伙食补贴" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column prop="subsidy" label="补助薪酬" align="center">
        </el-table-column>
        <el-table-column label="福利" align="center">
          <el-table-column prop="quality" label="社保" align="center">
          </el-table-column>
          <el-table-column prop="injury" label="伤病" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <el-table-column prop="other" label="其它" align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column prop="source" label="其它薪酬" align="center">
        </el-table-column>
        <el-table-column prop="total" label="薪酬总额" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template style="display: flex" slot-scope="scope">
            <el-button size="mini" type="primary" @click="SalaryDetailsFinish(scope.row)"><i class="el-icon-edit"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 2015年1月薪酬数据填报 -->
    <el-dialog :title="monthTitle" :visible.sync="UpdateSalarydialogVisible" width="720px" :before-close="UpdateSalarydialogVisibleClose" top="2vh" append-to-body>
      <el-form style="width: 100%" :model="UpdateSalaryDateModel" ref="UpdateSalaryDateRef" :rules="UpdateSalaryDateRules" :inline="true" label-width="120px">
        <el-form-item label="基本工资：" prop="fixed">
          <el-input v-model="UpdateSalaryDateModel.fixed"></el-input>
        </el-form-item>
        <el-form-item label="绩效薪酬：" prop="work">
          <el-input v-model="UpdateSalaryDateModel.work"></el-input>
        </el-form-item>
        <el-form-item label="岗位津贴：" prop="allowOne">
          <el-input v-model="UpdateSalaryDateModel.allowOne" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="职务津贴：" prop="allowTwo">
          <el-input v-model="UpdateSalaryDateModel.allowTwo" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="技能津贴：" prop="allowThree">
          <el-input v-model="UpdateSalaryDateModel.allowThree" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="住房补贴：" prop="allowFour">
          <el-input v-model="UpdateSalaryDateModel.allowFour" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="通信补贴：" prop="allowFive">
          <el-input v-model="UpdateSalaryDateModel.allowFive" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="交通补贴：" prop="allowSix">
          <el-input v-model="UpdateSalaryDateModel.allowSix" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="伙食补贴：" prop="allowSeven">
          <el-input v-model="UpdateSalaryDateModel.allowSeven" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="补助薪酬：" prop="subsidy">
          <el-input v-model="UpdateSalaryDateModel.subsidy"></el-input>
        </el-form-item>
        <el-form-item label="社保：" prop="quality">
          <el-input v-model="UpdateSalaryDateModel.quality"></el-input>
        </el-form-item>
        <el-form-item label="伤病：" prop="injury">
          <el-input v-model="UpdateSalaryDateModel.injury" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="其它：" prop="other">
          <el-input v-model="UpdateSalaryDateModel.other"></el-input>
        </el-form-item>
        <el-form-item label="其它薪酬：" prop="source">
          <el-input v-model="UpdateSalaryDateModel.source" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="薪酬总额：" prop="total">
          <el-input v-model="UpdateSalaryDateModel.total"></el-input>
        </el-form-item>
        <el-form-item label="薪酬总额：" prop="" style="visibility: hidden">
          <el-input></el-input>
        </el-form-item>
        <el-form-item class="depar-dia">
          <div class="btnCen">
            <el-button v-if="addUpdateClick" type="primary" :loading="addUpdateLoading" @click="addUpdateSalaryDate">提交</el-button>
            <el-button v-else type="info" :loading="addUpdateLoading"></el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  props: ["keshiId"],
  data() {
    // 只能输入数字正则
    var SalarycheckAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("请填写薪酬数据！"));
      }
      setTimeout(() => {
        // console.log("number", Number.isInteger(value))
        if (!/^\d+$|^\d*\.\d+$/g.test(value)) {
          callback(new Error("请输入数字信息！"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      // 薪酬数据填报的提交按钮
      addUpdateLoading: false,
      UpdateSalaryDateModel: {
        total: "", //薪酬总额
        fixed: "", //基本工资
        work: "", //绩效薪酬
        quality: "", //社保
        subsidy: "", //补助薪酬
        other: "", //其它
        source: "", //其它薪酬
        allowOne: "", //岗位津贴
        allowTwo: "", //职务津贴
        allowThree: "", //技能津贴
        allowFour: "", //住房补贴
        allowFive: "", //通信补贴
        allowSix: "", //交通补贴
        allowSeven: "", //伙食补贴
        injury: "", //伤病
      },
      // 科室薪酬数据填报的提交按钮
      addUpdateClick: true,
      UpdateSalaryDateRules: {
        total: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        fixed: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        work: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        quality: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        subsidy: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        allowOne: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        allowTwo: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        allowThree: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        allowFour: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        allowFive: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        allowSix: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        allowSeven: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        injury: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        other: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
        source: [
          {
            required: true,
            validator: SalarycheckAge,
            trigger: "blur",
          },
        ],
      },
      // 科室薪酬数据填报表格数据
      SalaryListtableData: [],
      // 总页数
      Salarytotal: 1,
      // 每页页数
      SalarypageNum: 1,
      // 每页显示条数
      SalarypageSize: 10,
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      //这条数据id
      id: "",
      // 总页数
      total: 1,
      // 每页显示条数
      pageSize: 10,
      // 科室id
      departmentId: window.sessionStorage.getItem("departmentId"),
      yearTitle: "",
      // 2016年科室工作量填报弹出框
      SalaryDetaildialogVisible: false,
      //   2015年科室工作量填报表格数据
      SalaryDetailtableData: [],
      //   2015年1月薪酬数据填报
      UpdateSalarydialogVisible: false,
      yearT: "",
      monthTitle: "",
      //   科室薪资年份表id
      sId: "",
      departId: this.keshiId,
      userType: window.sessionStorage.getItem("type"),
    };
  },
  watch: {
    keshiId: {
      handler(newName, oldName) {
        this.departId = newName;
        this.getDepartmentSalaryList();
      },
      deep: true,
    },
  },
  created() {
    // 0 医院用户   1 科室用户
    if (this.userType == "1") {
      this.departId = window.sessionStorage.getItem("departmentId");
    }
    this.getDepartmentSalaryList();
  },
  methods: {
    //修改科室薪资月份表
    addUpdateSalaryDate() {
      this.$refs.UpdateSalaryDateRef.validate(async (valid) => {
        if (valid) {
          this.addUpdateClick = false;
          this.addUpdateLoading = true;
          let data = _qs.stringify({
            departmentId: this.departId, //科室id
            id: this.id, //科室月份薪资表id
            sId: this.sId, //科室薪资年份表id
            total: this.UpdateSalaryDateModel.total, //薪酬总额
            fixed: this.UpdateSalaryDateModel.fixed, //基本工资
            work: this.UpdateSalaryDateModel.work, //绩效薪酬
            quality: this.UpdateSalaryDateModel.quality, //社保
            subsidy: this.UpdateSalaryDateModel.subsidy, //补助薪酬
            other: this.UpdateSalaryDateModel.other, //其它
            source: this.UpdateSalaryDateModel.source, //其它薪酬
            allowOne: this.UpdateSalaryDateModel.allowOne, //岗位津贴
            allowTwo: this.UpdateSalaryDateModel.allowTwo, //职务津贴
            allowThree: this.UpdateSalaryDateModel.allowThree, //技能津贴
            allowFour: this.UpdateSalaryDateModel.allowFour, //住房补贴
            allowFive: this.UpdateSalaryDateModel.allowFive, //通信补贴
            allowSix: this.UpdateSalaryDateModel.allowSix, //交通补贴
            allowSeven: this.UpdateSalaryDateModel.allowSeven, //伙食补贴
            injury: this.UpdateSalaryDateModel.injury, //伤病
          });
          let { data: res } =
              await this.$axios.updateDepartmentSalaryDetails(data);
          // console.log(res);
          this.addUpdateClick = true;
          this.addUpdateLoading = false;
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 200) {
            this.$message({
              message: "薪酬数据更新成功",
              type: "success",
            });
            this.UpdateSalarydialogVisible = false;
            this.getDepartmentSalary();
          } else {
            this.$message({
              message: res.data,
              type: "error",
            });
          }
        }
      });
    },
    UpdateSalarydialogVisibleClose() {
      this.addUpdateLoading = false;
      this.UpdateSalarydialogVisible = false;
      this.$refs.UpdateSalaryDateRef.resetFields();
      this.getDepartmentSalary();
    },
    // 点击弹出框的操作
    SalaryDetailsFinish(row) {
      this.monthTitle =
          this.yearT + "年" + row.month + "月科室薪酬数据填报";
      this.id = row.id;
      this.sId = row.sId;
      this.UpdateSalaryDateModel.total = row.total; //薪酬总额
      this.UpdateSalaryDateModel.fixed = row.fixed; //基本工资
      this.UpdateSalaryDateModel.work = row.work; //绩效薪酬
      this.UpdateSalaryDateModel.quality = row.quality; //社保
      this.UpdateSalaryDateModel.subsidy = row.subsidy; //补助薪酬
      this.UpdateSalaryDateModel.other = row.other; //其它
      this.UpdateSalaryDateModel.source = row.source; //其它薪酬
      this.UpdateSalaryDateModel.allowOne = row.allowOne; //岗位津贴
      this.UpdateSalaryDateModel.allowTwo = row.allowTwo; //职务津贴
      this.UpdateSalaryDateModel.allowThree = row.allowThree; //技能津贴
      this.UpdateSalaryDateModel.allowFour = row.allowFour; //住房补贴
      this.UpdateSalaryDateModel.allowFive = row.allowFive; //通信补贴
      this.UpdateSalaryDateModel.allowSix = row.allowSix; //交通补贴
      this.UpdateSalaryDateModel.allowSeven = row.allowSeven; //伙食补贴
      this.UpdateSalaryDateModel.injury = row.injury; //伤病
      this.UpdateSalarydialogVisible = true;
    },
    // 点击操作
    SalaryDetails(row) {
      this.yearT = row.year;
      this.yearTitle = row.year + "年科室薪酬数据";
      this.sId = row.id;
      this.getDepartmentSalary();
    },
    // 科室薪资月份列表
    async getDepartmentSalary() {
      let data = {
        sId: this.sId, //科室薪资年份表id
      };

      let { data: res } = await this.$axios.getDepartmentSalaryDetails(
          data
      );
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 500) {
        this.$message({
          message: "获取科室薪资月份列表失败",
          type: "error",
        });
      } else if (res.code == 200) {
        this.SalaryDetaildialogVisible = true;
        this.SalaryDetailtableData = res.data;
      }
    },
    SalaryDetaildialogVisibleClose() {
      this.SalaryDetaildialogVisible = false;
      this.getDepartmentSalaryList();
    },
    // 获取科室薪酬数据列表的分页
    handleSalaryCurrentChange(val) {
      this.SalarypageNum = val;
      this.getDepartmentSalaryList();
    },
    // 获取科室薪酬数据列表 分页
    async getDepartmentSalaryList() {
      let data = {
        pageNum: this.SalarypageNum,
        pageSize: this.SalarypageSize,
        departmentId: this.departId, //科室id
      };
      let { data: res } = await this.$axios.getDepartmentSalaryList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 500) {
        this.$message({
          message: "获取医院所属科室列表失败",
          type: "error",
        });
      } else if (res.code == 200) {
        this.SalaryListtableData = res.rows;
        this.Salarytotal = res.total;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.hosform {
  display: flex;
  .hosdanwei {
    margin-left: 10px;
  }
}
/deep/ .tanchu .el-dialog {
  margin: 0 auto;
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 30px;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

.btnCen {
  text-align: center;
  .el-button {
    width: 150px;
  }
}
</style>
